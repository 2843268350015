<template>
  <!--  主管审批  -->
  <div class="m3-width-full m3-flex-col m3-justify-content-start">
    <a-form ref="editFormRef" layout="horizontal"
            :model="editForm" :rules="editFormRules"
            :label-col="labelCol" :wrapper-col="wrapperCol"
            class="m3-width-full m3-flex-1 m3-relative">
      <a-form-item label="系统分析">
        <span v-if="infractionsDesc">该主叫为本年第{{ infractionsDesc }}次违规</span>
        <span v-else>该主叫本年未出现违规</span>
      </a-form-item>
      <a-form-item label="违规来源">
        <a-button disabled>{{
            orderFromDesc
          }}
        </a-button>
      </a-form-item>
      <a-form-item  v-if="record.orderFrom==1" label="运营商处罚说明">
        <a-textarea disabled :value="oprPenaltiesDesc">
        </a-textarea>
      </a-form-item>
      <a-form-item label="处罚决定">
        <div class="m3-flex">
          <a-select disabled v-model:value="punishTypeDesc" :options="punishTypeList"
                    class="m3-flex-2"
                    @change="punishTypeChange"></a-select>
          <span v-if="punishValueDesc" class="m3-margin-horizontal-s">-</span>
            <a-select v-if="punishPropNotice"
                    disabled v-model:value="punishPropNotice"
                    :options="penaltyList"
                    class="m3-flex-5"></a-select>
           <span v-if="punishValueDesc" class="m3-margin-horizontal-s">-</span>
          <a-select v-if="punishValueDesc"
                    disabled v-model:value="punishValueDesc"
                    :options="punishValList"
                    class="m3-flex-5"></a-select>
        </div>
      </a-form-item>
      <a-form-item label="违规说明">
        <div class="m3-relative">
          <a-textarea disabled  v-model:value="violDesc">
          </a-textarea>
          <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                    @click="copyViolDesc">复制
          </a-button>
        </div>
      </a-form-item>
      <a-form-item label="是否通过" name="approval">
        <a-radio-group :disabled="formDisabled" v-model:value="editForm.approval" :options="approvalList"
                       button-style="solid" @change="approvalChange"/>
      </a-form-item>
      <a-form-item label="处罚决定" name="punishConfType">
        <div class="m3-flex">
          <a-select :disabled="formDisabled" v-model:value="editForm.punishConfType" :options="punishTypeList"
                    class="m3-flex-2"
                    @change="punishTypeChange"
                    ></a-select>
          <span class="m3-margin-horizontal-s">-</span>
           <a-select :disabled="formDisabled" v-model:value="editForm.punishFinalNotice"
                    :options="penaltyList"
                    @change="penaltyNoticeChange"
                    class="m3-flex-5"></a-select>
          <span class="m3-margin-horizontal-s">-</span>
          <a-select :disabled="formDisabled" v-model:value="editForm.punishConfVal"
                    :options="punishValList"
                    class="m3-flex-5"></a-select>
        </div>
      </a-form-item>
      <a-form-item label="审批意见" name="disapprovalReason">
        <div class="m3-relative">
          <a-textarea :disabled="formDisabled" v-model:value="editForm.disapprovalReason"
                      :rows="4">
          </a-textarea>
          <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                    @click="copyText">复制
          </a-button>
        </div>
      </a-form-item>
    </a-form>

    <div class="m3-absolute m3-flex m3-justify-content-end m3-padding-s"
         :style="{bottom:'20px',right:'20px'}">
      <a-button type="primary" :loading="buttonLoading" @click="pre">上一步</a-button>
      <a-button class="m3-margin-left-s" type="primary" :loading="buttonLoading" @click="next">下一步</a-button>
      <a-button class="m3-margin-left-s" @click="cancel">取消</a-button>
    </div>
  </div>
</template>

<script>
import AButton from "ant-design-vue/lib/button/button";
import {computed, reactive, ref, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {Form, message} from "ant-design-vue";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import {copy} from "@/utils/common";
import {modalConfirm} from "@/utils/common/modalConfirm";
import ObjectUtil from "@/utils/common/ObjectUtil";
import {
  MAX_FORBIDDEN_HOUR,
  orderFromDesc, PUNISH_AMOUNT_LIST, PUNISH_DURATION_LIST,
  PUNISH_TYPE_LIST,
  punishTypeDesc,
  punishValueDesc
} from "@/views/open/workOrder/WorkOrder";
import {useStore} from "vuex";

export default {
  name: "StepApproval",
  emits: ['next'],
  components: {AButton},
  props: {
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {record} = toRefs(props);
    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');

    let formDisabled = computed(() => !EmptyUtil.isEmpty(record.value?.id) && record.value?.status >= 9);
    let buttonLoading = ref(false);
    let editFormRef = ref();
    let editForm = reactive({
      punishFinalNotice:null, //处罚通知
      id: null,//质检记录ID
      approval: 1,//审核通过，0-不通过 1-通过
      disapprovalReason: null,//不通过原因
      punishConfType: null,//处罚类型 管理员二次修改 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
      punishConfVal: null,//处罚量 管理员二次修改 punish_prop_type为0，此值为null； punish_prop_type为1或2，此值为金额，单位元；punish_prop_type为3，此值为时长，单位小时；
    });

    let approvalChange = () => {
      if (editForm.approval == 0) {
        editFormRules.disapprovalReason = [{required: true, message: '请输入审批意见', trigger: 'blur',}];
      } else {
        delete editFormRules.disapprovalReason;
      }
    }
    let punishTypeChange = () => {
      editForm.punishConfVal = null;
      editForm.punishFinalNotice=null
      console.log('0000000');
    }
    let editFormRules = reactive({
      approval: [{required: true, type: 'number', message: '请选择审核是否通过', trigger: 'blur',}],
      // disapprovalReason: [{required: true, message: '请输入禁用原因说明', trigger: 'blur',}],
      punishConfType: [{required: true, type: 'number', message: '请选择处罚类型', trigger: 'change',}],
      // punishPropVal: [{required: true, message: '请选择处罚量', trigger: 'change',}],
    })
    let resetForm = () => {
      editForm.approval = 1;
      editForm.disapprovalReason = null;
      editForm.punishConfType = null;
      editForm.punishConfVal = null;
      editForm.punishFinalNotice=null
    }
    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos} = useForm(editForm, editFormRules);

    watch(record, (newV, preV) => {
      if (!EmptyUtil.isEmpty(newV)) {  
        resetForm();
        Object.assign(editForm, newV);
        console.log('StepApproval watch record', editForm)
        if (EmptyUtil.isEmpty(editForm.punishConfType) && !EmptyUtil.isEmpty(newV?.punishPropType)) {
          editForm.punishConfType = newV?.punishPropType;
        }
        if (EmptyUtil.isEmpty(editForm.punishFinalNotice) && EmptyUtil.isEmpty(newV?.punishConfVal)) {
                 editForm.punishConfVal = newV?.punishPropVal;
        }else{
          editForm.punishConfVal = newV?.punishConfVal;
        }
        //   if (EmptyUtil.isEmpty(editForm.punishConfVal) && !EmptyUtil.isEmpty(newV?.punishPropVal)) {
        //       console.log(editForm.punishFinalNotice,'77777777777777');

        // }
        
       
        if (EmptyUtil.isEmpty(editForm.punishFinalNotice) && !EmptyUtil.isEmpty(newV?.punishPropNotice)) {
          editForm.punishFinalNotice = newV?.punishPropNotice;
        
        }
       
        if (editForm.punishConfType==1||editForm.punishConfType==2||editForm.punishConfType==4) {
           editForm.punishFinalNotice =''
        }
        
        
      } else {
        resetForm();
      }
    }, {immediate: true})


    //是否通过 0-不通过 1-通过
    let approvalList = [
      {
        value: 0,
        label: '不通过',
      },
      {
        value: 1,
        label: '通过',
      },
    ]
    //处罚类型 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
    let punishTypeList = PUNISH_TYPE_LIST

    //处罚量 punish_prop_type为0，此值为null； punish_prop_type为1或2，此值为金额，单位元；punish_prop_type为3，此值为时长，单位小时；
    let punishValList = computed(() => {
      let res = [];
      if (editForm.punishConfType == 0) {
        res = []
       } else if (editForm.punishConfType == 1 || editForm.punishConfType == 2 || editForm.punishConfType == 4 || editForm.punishConfType == 5) {
        res = PUNISH_AMOUNT_LIST
      } else if (editForm.punishConfType == 3||editForm.punishConfType == 10) {
        // res = PUNISH_DURATION_LIST
         if (editForm.punishConfType == 3) {
              if (editForm.punishFinalNotice=='被叫反感') {
                    res=[
            {label: '4小时', value: 4,},
            {label: '8小时', value: 8,},
            {label: '12小时', value: 12,},
            {label: '24小时', value: 24,},
            {label: '48小时', value: 48,}, 
                 ] 
              }else if(editForm.punishFinalNotice=='自称官方'){
                     res = [
                {label: '永久', value: 867240,},
                 ];
              }else if(editForm.punishFinalNotice=='其他原因'){
                  res=[
            {label: '4小时', value: 4,},
            {label: '8小时', value: 8,},
            {label: '12小时', value: 12,},
            {label: '24小时', value: 24,},
            {label: '48小时', value: 48,}, 
            {label: '永久', value: 867240,},
                 ] 
              }
         }    

         if (editForm.punishConfType == 10) {
              if (editForm.punishFinalNotice=='被叫反感') {
                    res=[
            {label: '1小时', value: 1,},
            {label: '2小时', value: 2,},
            {label: '4小时', value: 4,},
            {label: '8小时', value: 8,},
            {label: '12小时', value: 12,},
            {label: '24小时', value: 24,},
            {label: '48小时', value: 48,}, 
                 ] 
              }else if(editForm.punishFinalNotice=='自称官方'){
                     res = [
                {label: '永久', value: 867240,},
                 ];
              }else if(editForm.punishFinalNotice=='其他原因'){
                  res=[
            {label: '1小时', value: 1,},
            {label: '2小时', value: 2,},
            {label: '4小时', value: 4,},
            {label: '8小时', value: 8,},
            {label: '12小时', value: 12,},
            {label: '24小时', value: 24,},
            {label: '48小时', value: 48,}, 
            {label: '永久', value: 867240,},
                 ] 
              }
         }  
        //  if (condition) {
          
        //  }

//           if (editForm.punishFinalNotice=='被叫反感') {
//           if (editForm.punishConfType == 3) {
//                res=[
//             {label: '4小时', value: 4,},
//             {label: '8小时', value: 8,},
//             {label: '12小时', value: 12,},
//             {label: '24小时', value: 24,},
//             {label: '48小时', value: 48,}, 
//                  ]   
//           }else if(editForm.punishConfType == 10){
//               res=[
//             {label: '1小时', value: 1,},
//             {label: '2小时', value: 2,},
//             {label: '4小时', value: 4,},
//             {label: '8小时', value: 8,},
//             {label: '12小时', value: 12,},
//             {label: '24小时', value: 24,},
//             {label: '48小时', value: 48,}, 
//                  ]   
//           }
             
//         }
//         if (editForm.punishFinalNotice=='自称官方') {
//            res = [
//     {label: '永久', value: 867240,},
// ];
//         }
//         if (editForm.punishFinalNotice=='其他原因') {
//               res=[
//             {label: '1小时', value: 1,},
//             {label: '2小时', value: 2,},
//             {label: '4小时', value: 4,},
//             {label: '8小时', value: 8,},
//             {label: '12小时', value: 12,},
//             {label: '24小时', value: 24,},
//             {label: '48小时', value: 48,}, 
//             {label: '永久', value: 867240,},
//                  ]  
//         }
      }
      return res;
    })
        const penaltyNoticeChange = (value) => {
      editForm.punishFinalNotice = value;
      console.log(editForm.punishFinalNotice,value);
      editForm.punishConfVal=null
      // if (editForm.punishPropType == 5) {
      //    if (value=='被叫反感') {
      //     editForm.violDesc ='被叫反感请礼貌挂机，切勿骂人互怼，主叫禁用2小时且罚款100元'
      //    }
      // }else{
      //    if (value=='零首付') {
      //   editForm.violDesc ='请勿涉及零首付等敏感词/业务，请整改话术，本次提醒'
      // }else if(value=='装修贷'){
      //   editForm.violDesc ='请勿涉及装修贷等敏感词/业务，请整改话术，本次提醒'
      // }else if(value=='被叫反感'){
      //    editForm.violDesc ='被叫反感请礼貌挂机，切勿骂人互怼，主叫禁用'
      // }else if(value=='自称官方'){
      //    editForm.violDesc ='不可以自称官方，只能说自己公司，请整改话术，主叫关停'
      // }
      // }
      
      // editForm.violDesc = editForm.penaltyNotice;  
    };
    let copyViolDesc = () => {
      if (!EmptyUtil.isEmpty(record.value?.violDesc)) {
        copy(record.value?.violDesc)
      } else {
        message.warning('暂无内容');
      }
    }
    let penaltyList=computed(() => {
      let res = [];
      if (editForm.punishConfType == 0) {
        res = [{
        value:'零首付',
        label: '零首付',
    },
    {
        value: '装修贷',
        label: '装修贷',
    },
    {
        value: '其他原因',
        label: '其他原因',
    },]
      } else if (editForm.punishConfType == 1 || editForm.punishConfType == 2 )  {
        res = [];
      } else if (editForm.punishConfType == 3 ||editForm.punishConfType == 10) {
        res = [{
        value:'被叫反感',
        label: '被叫反感',
    },
    {
        value:'自称官方',
        label: '自称官方',
    },
    {
        value: '其他原因',
        label: '其他原因',
    },]
      }else if(editForm.punishConfType == 5){
        console.log(editForm.punishConfType);
         res = [{
        value:'被叫反感',
        label: '被叫反感',
    },
    {
        value: '其他原因',
        label: '其他原因',
    },
   ]
      }
      return res;
    })
    let copyText = () => {
      if (!EmptyUtil.isEmpty(editForm.disapprovalReason)) {
        copy(editForm.disapprovalReason)
      } else {
        message.warning('请输入禁用原因');
      }
    }
    let cancel = () => {
      context.emit('next', -1);
    }

    let pre = () => {
      context.emit('next', 3);
    }
    let next = async () => {
      let confirm = true;
      let nextStatus = 9;
      if (!formDisabled.value) {
        if (!isAdmin.value) {
          await modalConfirm('抱歉，当前用户没有操作权限！');
          return;
        }
        let valid = await safePromise(editFormRef.value?.validate());
         if (editForm.punishConfType == 0 && EmptyUtil.isEmpty(editForm.punishFinalNotice)) {
          message.error('请选择处罚通知');
          return;
        }
        if (editForm.punishConfType == 5 && EmptyUtil.isEmpty(editForm.punishFinalNotice)) {
          message.error('请选择处罚通知');
          return;
        }
        if (editForm.punishConfType != 0 && EmptyUtil.isEmpty(editForm.punishConfVal)) {
          message.error('请选择处罚量');
          return;
        }
       
        //   disapprovalReason: [{required: true, message: '请输入禁用原因说明', trigger: 'blur',}],
        if (editForm.approval == 0 && EmptyUtil.isEmpty(editForm.disapprovalReason)) {
          message.error('请输入禁用原因说明');
          return;
        }
        console.log('next valid', valid, EmptyUtil.isEmpty(valid?.errorFields));
        if (EmptyUtil.isEmpty(valid?.errorFields)) {
          //在编辑阶段，需要弹窗提示
          confirm = await modalConfirm('确认提交？');
        } else {
          confirm = false;
        }

        if (confirm) {
          buttonLoading.value = true;
          let params = ObjectUtil.toParams(editForm);
          let res = await safePromise(_lineService._workOrderService.approval(params));
          if (res?.code == 200) {
            // record.value.id = res.data?.id;
            if (editForm.approval == 0) {
              //审批不通过，状态改为18（已完结）
              nextStatus = 18;
            }
            record.value.status = nextStatus;
            record.value.approval = editForm.approval;//
            record.value.disapprovalReason = editForm.disapprovalReason;//
            record.value.punishConfType = editForm.punishConfType;//
            record.value.punishConfVal = editForm.punishConfVal;//
            record.value.punishFinalNotice = editForm.punishFinalNotice;//
            record.value.punishPropNotice = editForm.punishPropNotice;//
            context.emit('next', nextStatus);//传递下一个status进行跳转
            // resetForm();
          } else {
            message.error(res?.message || res?.msg || '提交失败');
          }
          buttonLoading.value = false;
        }
      } else {
        if (record.value.approval == 0) {
          //审批不通过，状态改为18（已完结）
          nextStatus = 18;
        }
        context.emit('next', nextStatus);//传递下一个status进行跳转
      }
    }

    return {
      labelCol: {span: 3},
      wrapperCol: {span: 14},
      formDisabled, editForm, editFormRules, editFormRef,
      punishTypeChange, approvalChange,
      punishTypeList, punishValList, approvalList,
      buttonLoading, pre, next, cancel, copyText, copyViolDesc,penaltyNoticeChange,penaltyList
    }
  },
  computed: {
    infractionsDesc() {
      return this.record?.infractions
    },
    orderFromDesc() {
      //来源 0-平台质检 1-运营商质检
      return orderFromDesc(this.record?.orderFrom)
    },
     oprPenaltiesDesc: {
      get() {
        return this.record?.oprPenalties;
      },
      set(value) {
        this.record.oprPenalties = value;
      }
    },
     violDesc: {
      get() {
        return this.record?.violDesc;
      },
      set(value) {
        this.record.violDesc = value;
      }
    },
    punishPropNotice: {
      get() {
        return this.record?.punishPropNotice;
      },
      set(value) {
        this.record.punishPropNotice = value;
      }
    },
    //    return punishPropNotice(this.record.punishPropNotice)
    // },
     punishTypeDesc: {
      get() {
        const option = this.punishTypeList.find(
          (opt) => String(opt.value) === String(this.record.punishPropType)
        );
        return option ? option.label : '';
      },
      set(label) {
        const option = this.punishTypeList.find((opt) => opt.label === label);
        this.record.punishPropType = option ? Number(option.value) : null;
      }
    },
    punishValueDesc() {
      return punishValueDesc(this.record.punishPropType, this.record?.punishPropVal)
    },
  },
}
</script>

<style scoped>

</style>