<template>
  <!--  派发工单  -->
  <div class="m3-width-80 m3-flex-col m3-justify-content-start">

    <a-form
        ref="editFormRef" layout="horizontal"
        :model="editForm"
        :label-col="labelCol" :wrapper-col="wrapperCol"
        class="m3-width-full m3-margin-top-xl">
      <a-form-item label="派发方式" name="approval">
        <a-radio-group :disabled="formDisabled" v-model:value="editForm.noticeType" :options="noticeTypeList"
                       button-style="solid"/>
      </a-form-item>
      <a-form-item label="发送内容" name="reportDesc">
        <div class="m3-relative">
          <a-textarea :disabled="formDisabled" v-model:value="editForm.noticeDesc" :rows="9">
          </a-textarea>
          <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                    @click="copyText">
            复制
          </a-button>
        </div>
      </a-form-item>
      <a-form-item label="是否通知">
        <a-checkbox :disabled="formDisabled" v-model:checked="checked">我已通知</a-checkbox>
      </a-form-item>
    </a-form>

    <div class="m3-absolute m3-flex m3-justify-content-end m3-padding-s"
         :style="{bottom:'20px',right:'20px'}">
      <a-button type="primary" :loading="buttonLoading" @click="pre">上一步</a-button>
      <a-button class="m3-margin-left-s" type="primary" :loading="buttonLoading" @click="next">下一步</a-button>
      <a-button class="m3-margin-left-s" @click="cancel">取消</a-button>
    </div>
  </div>
</template>

<script>
import AButton from "ant-design-vue/lib/button/button";
import {computed, reactive, ref, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {Form, message} from "ant-design-vue";
import {copy} from "@/utils/common";
import {modalConfirm} from "@/utils/common/modalConfirm";
import ObjectUtil from "@/utils/common/ObjectUtil";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import {useStore} from "vuex";
import moment from "moment";
import {APPEAL_EXPIRED_DURATION} from "@/views/open/workOrder/WorkOrder";
import {lineCheckDetail} from "@/api/open/voiceCheck/voiceCheck";

export default {
  name: "StepNotice",
  emits: ['next'],
  components: {AButton},
  props: {
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {record} = toRefs(props);
    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');

    let formDisabled = computed(() => !EmptyUtil.isEmpty(record.value?.id) && record.value?.status >= 12);
    let buttonLoading = ref(false);
    let editFormRef = ref();
    let checked = ref(false);
    let editForm = reactive({
      id: null,//id
      noticeType: 0,//通知方式 0-手动 1-系统自动
      noticeDesc: null,//通知内容的一段文字
    });

    let editFormRules = {
      noticeType: [{required: true, type: 'number', message: '请选择派发方式', trigger: 'blur',}],
      noticeDesc: [{required: true, message: '请输入通知内容', trigger: 'blur',}],
    }
    let resetForm = () => {
      editForm.id = null;
      editForm.noticeType = 0;
      editForm.noticeDesc = null;
    }
    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos} = useForm(editForm, editFormRules);

    let lineRecord = ref({});
    let getLineRecordDetail = async () => {
      if (EmptyUtil.isEmpty(record.value?.checkId)) {
        lineRecord.value = {};
        return;
      }
      // 获取质检记录详情，主要用于获取通话时间
      let res = await safePromise(lineCheckDetail({id: record.value?.checkId}));
      if (res.code == 200 && res.data) {
        lineRecord.value = res?.data || {};
      } else {
        lineRecord.value = {};
      }
    }

    let generateNoticeDesc = async () => {
      let res = '';
      if (!EmptyUtil.isEmpty(record.value)) {
        // res += '线路：' + (record.value?.amountName || '') + '\r\n';
        // res += '用户：' + (record.value?.nickname || '') + '\r\n';
        // res += '主叫：' + (record.value?.caller || '') + '\r\n';
        // res += '被叫：' + (record.value?.callee || '') + '\r\n';
        // res += '违规说明：' + (record.value?.violDesc || '') + '\r\n';
        // res += '话单详情：' + (record.value?.violDesc || '') + '\r\n';
        // res += '如需申诉，请在24小时内登录数字云平台语音质检-线路质检新版提交申诉材料';

        // await getLineRecordDetail();//取消，取callTime
        res = `违规工单号：${
            record.value?.orderNo || ''}\r\n线路：${
            record.value?.amountName || ''}\r\n用户：${
            record.value?.nickname || ''}\r\n主叫：${
            record.value?.caller || ''}\r\n被叫：${
            record.value?.callee || ''}\r\n违规说明：${
            record.value?.violDesc || ''}\r\n${record.value?.callTime ?
            ('通话时间：' + record.value?.callTime + '\r\n')
            : ''}${record.value?.checkId ?
            ('话单详情：http://221.236.23.234:180/#/wechat/record?id=' + record.value?.checkId + '&type=2' + '\r\n')
            : ''} ${record.value?.orderFrom ?(
              `该号码被运营商质检到违规，如需申诉，请在${APPEAL_EXPIRED_DURATION / (60 * 60 * 1000)}小时内提交申诉材料，请贵公司在接到该通知后尽快核实并处理，感谢您的合作！`):
            (`如需申诉，请在${APPEAL_EXPIRED_DURATION / (60 * 60 * 1000)}小时内登录 数字云平台 - 语音质检 - 质检工单菜单提交申诉材料，若被运营商质检到并罚款，本司保留罚款权力，请贵公司在接到该通知后核实并处理，感谢您的合作！`)}   
        `;//这里的type参数，参考wechat/record页面
        editForm.noticeDesc = res;
      }
    }
    watch(record, (newV, preV) => {
      console.log(newV,preV);
      if (!EmptyUtil.isEmpty(newV)) {
        resetForm();
        Object.assign(editForm, newV);
        console.log('StepNotice watch record', editForm)
        editForm.noticeType = !EmptyUtil.isEmpty(editForm.noticeType) ? editForm.noticeType : 0;
        if (EmptyUtil.isEmpty(editForm.noticeDesc)) {
          generateNoticeDesc();
        }
        if (newV.status >= 12) {
          checked.value = true;
        }
      } else {
        resetForm();
      }
    }, {immediate: true})


    //通知方式 0-手动 1-系统自动
    let noticeTypeList = [
      {
        value: 0,
        label: '手动发送',
      },
      {
        value: 1,
        label: '系统推送',
        disabled: true,
      },
    ]
    let copyText = () => {
      if (!EmptyUtil.isEmpty(editForm.noticeDesc)) {
        copy(editForm.noticeDesc)
      } else {
        message.warning('请输入发送内容');
      }
    }
    let cancel = () => {
      context.emit('next', -1);
    }

    let pre = async () => {
      context.emit('next', 6);
    }
    let next = async () => {
      console.log(record.value,'000000000777');
      let confirm = true;
      let nextStatus = 12;
      if (!formDisabled.value) {
        if (!isAdmin.value) {
          await modalConfirm('抱歉，当前用户没有操作权限！');
          return;
        }
        let valid = await safePromise(editFormRef.value?.validate());
        if (EmptyUtil.isEmpty(valid?.errorFields)) {
          //在编辑阶段，需要弹窗提示
          confirm = await modalConfirm('确认提交？');
        } else {
          confirm = false;
        }
        if (confirm) {
          if (!checked.value) {
            await modalConfirm('请勾选“我已通知”');
            return;
          }
          buttonLoading.value = true;
          let params = ObjectUtil.toParams(editForm);
          let res = await safePromise(_lineService._workOrderService.notice(params));
          if (res?.code == 200) {
            record.value.status = nextStatus;
            record.value.noticeTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
            record.value.reportDesc = editForm.reportDesc;
            context.emit('next', nextStatus);//传递下一个status进行跳转
            // resetForm();
          } else {
            message.error(res?.message || res?.msg || '提交失败');
          }
          buttonLoading.value = false;
        }
      } else {
        context.emit('next', nextStatus);//传递下一个status进行跳转
      }
    }

    return {
      labelCol: {span: 3},
      wrapperCol: {span: 14}, checked, noticeTypeList,
      formDisabled, editForm, editFormRules, editFormRef,
      buttonLoading, pre, next, cancel, copyText,
    }
  },
}
</script>

<style scoped>

</style>