<template>
  <!--  客户申诉  -->
  <div class="m3-width-80 m3-flex-col m3-justify-content-start">
    <a-form ref="editFormRef" layout="horizontal"
            :model="editForm" :rules="editFormRules"
            :label-col="labelCol" :wrapper-col="wrapperCol"
            class="m3-width-full m3-flex-1 m3-relative">
      <a-form-item label="工单状态">
        <span v-if="!isExpired&&!isOrderAppealed">未申诉，倒计时<span
            class="m3-margin-horizontal-xs md-body-medium">{{ countDownDesc }}</span></span>
        <span v-else-if="isExpired&&!isOrderAppealed">逾期未申诉</span>
        <span v-else-if="formDisabled">已处理</span>
      </a-form-item>
      <a-form-item label="处罚结果">
        <div class="m3-flex">
          <a-select disabled v-model:value="punishTypeDesc" :options="punishTypeList"
                    class="m3-flex-2"></a-select>
                     <span v-if="punishPropNotice" class="m3-margin-horizontal-s">-</span>
            <a-select v-if="punishPropNotice"
                    disabled v-model:value="punishPropNotice"
                    :options="penaltyList"
                    class="m3-flex-5"></a-select>
          <span v-if="punishValueDesc" class="m3-margin-horizontal-s">-</span>
          <a-select v-if="punishValueDesc"
                    disabled v-model:value="punishValueDesc"
                    :options="punishValList"
                    class="m3-flex-5"></a-select>
        </div>
      </a-form-item>
      <a-form-item label="违规说明">
        <div class="m3-relative">
          <a-textarea disabled v-model:value="violDesc">
          </a-textarea>
          <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                    @click="copyViolDesc">复制
          </a-button>
        </div>
      </a-form-item>
      <a-form-item v-if="record.checkId" label="违规话单">
        <a-button @click="showLineRecordDialog">查看</a-button>
      </a-form-item>
      <a-form-item label="申诉说明" name="appealContent">
        <div class="m3-relative">
          <a-textarea :disabled="formDisabled" v-model:value="editForm.appealContent"
                      :rows="4">
          </a-textarea>
          <a-button class="m3-absolute" :style="{bottom:'16px',right:'16px'}" size="small" type="link"
                    @click="copyText">复制
          </a-button>
        </div>
      </a-form-item>
      <a-form-item label="整改方案" name="appealAttachment">
        <a-upload
            :disabled="formDisabled"
            :action="imageUpdate.uploadUrl"
            :headers="imageUpdate.header"
            :name="imageUpdate.name"
            v-model:file-list="imageUpdate.fileList"
            list-type="picture-card"
            @preview="onImagePreview"
            :before-upload="beforeImageUpload">
          <div v-if="!formDisabled">
            <plus-outlined/>
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </a-form-item>
    </a-form>

    <div class="m3-absolute m3-flex m3-justify-content-end m3-padding-s"
         :style="{bottom:'20px',right:'20px'}">
      <a-button v-if="isAdmin" type="primary" :loading="buttonLoading" @click="pre">上一步</a-button>
      <a-button class="m3-margin-left-s" type="primary" :loading="buttonLoading" @click="next">下一步</a-button>
      <a-button class="m3-margin-left-s" @click="cancel">取消</a-button>
    </div>
  </div>
  <a-modal :visible="imagePreviewDialogVisible" :footer="null" @cancel="imagePreviewDialogVisible = false">
    <img alt="previewImage" style="width: 100%" :src="previewImage"/>
  </a-modal>
</template>

<script>
import AButton from "ant-design-vue/lib/button/button";
import {computed, onUnmounted, reactive, ref, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {Form, message} from "ant-design-vue";
import {copy} from "@/utils/common";
import {safePromise} from "@/utils/common/safePromise";
import {modalConfirm} from "@/utils/common/modalConfirm";
import ObjectUtil from "@/utils/common/ObjectUtil";
import _lineService from "@/api/open/LineQualityInspections";
import {
  APPEAL_EXPIRED_DURATION,
  MAX_FORBIDDEN_HOUR,
  orderFromDesc, PUNISH_AMOUNT_LIST, PUNISH_DURATION_LIST, PUNISH_TYPE_LIST,
  punishTypeDesc,
  punishValueDesc
} from "@/views/open/workOrder/WorkOrder";
import Cookies from "js-cookie";
import {ElMessage} from "element-plus";
import img from "@/api/common/img";
import {useStore} from "vuex";

export default {
  name: "StepAppeal",
  emits: ['next', 'showLineRecordDialog'],
  components: {AButton},
  props: {
    record: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {record} = toRefs(props);
    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');

    let expiredDuration = APPEAL_EXPIRED_DURATION;
    let isExpired = computed(() => {
      let noticeTime = record.value?.noticeTime;
      // let noticeTime = '2023-05-06 08:47:32';
      if (!EmptyUtil.isEmpty(noticeTime)) {
        noticeTime = new Date(noticeTime);
        if (noticeTime) {
          let expiredTime = new Date(noticeTime.getTime() + expiredDuration);
          // console.log('StepAppeal isExpired noticeTime:', noticeTime, expiredTime, new Date() - expiredTime)
          return (new Date() - expiredTime) > 0;
        }
      }
      return true;
    })
    let formDisabled = computed(() => !EmptyUtil.isEmpty(record.value?.id) && (record.value?.status >= 15 || isExpired.value));
    let buttonLoading = ref(false);
    let editFormRef = ref();
    let editForm = reactive({
      id: null,//质检记录ID
      appealContent: null,//申诉内容的一段文字
      appealAttachment: null,//申诉内容上传图片集合
    });


    let imagePreviewDialogVisible = ref(false);
    let previewImage = ref('');
    let imageUpdate = reactive({
      name: 'file',
      // uploadUrl: '/api/system/upload/file', // 请求地址,
      // uploadUrl: 'api/system/upload/important-file', // 永久文件上传,
      uploadUrl: 'api/web/upload/important-file', // 图片上传,
      // uploadUrl: '/system/upload/file', // 临时文件上传,
      header: {Authorization: Cookies.get('Authorization')}, // 请求头部
      fileList: [], //文件列表
    });
    let beforeImageUpload = (file, fileList) => {
      // console.log('beforeImageUpload', file, fileList);
      let canUpload = file?.type == 'image/png' || file?.type == 'image/jpeg';
      if (!canUpload) {
        ElMessage.error('只支持图片类型：png/jpg')
      }
      //由于路由代理问题，选择手动上传，见confirm方法
      return false;
    };
    let getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
    let onImagePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      imagePreviewDialogVisible.value = true;
      console.log('onImagePreview', file, previewImage.value);
    };

    let countDownDesc = ref('');
    let countDownId = null;
    let countDownTotal = expiredDuration;

    function formatDuring(mss) {
      let days = parseInt(mss / (1000 * 60 * 60 * 24));
      let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = ((mss % (1000 * 60)) / 1000).toFixed(0);
      let res = '';
      days = days > 0 ? days < 10 ? ('0' + days) : days : '';
      hours = hours > 0 ? hours < 10 ? ('0' + hours) : hours : '';
      minutes = minutes > 0 ? minutes < 10 ? ('0' + minutes) : minutes : '';
      seconds = seconds > 0 ? seconds < 10 ? ('0' + seconds) : seconds : '00';
      res += !EmptyUtil.isEmpty(days) ? (days + ' ') : '';
      res += !EmptyUtil.isEmpty(hours) ? (hours + ':') : '';
      res += !EmptyUtil.isEmpty(minutes) ? (minutes + ':') : '';
      res += !EmptyUtil.isEmpty(seconds) ? (seconds + '') : '';
      return res;
    }

    let countDown = () => {
      let noticeTime = record.value?.noticeTime;
      if (!EmptyUtil.isEmpty(noticeTime)) {
        noticeTime = new Date(noticeTime);
        if (noticeTime) {
          let expiredTime = new Date(noticeTime.getTime() + expiredDuration);
          countDownTotal = expiredTime.getTime() - new Date().getTime();
          // console.log('StepAppeal countDown countDownTotal:', countDownTotal)
        }
      }
      if (countDownId) {
        clearInterval(countDownId)
      }
      countDownId = setInterval(() => {
        countDownTotal -= 1000;
        countDownDesc.value = formatDuring(countDownTotal);
        if (countDownTotal <= 0) {
          clearInterval(countDownId);
        }
      }, 1000);
    }

    let editFormRules = {
      appealContent: [{required: true, message: '请输入申诉说明', trigger: 'blur',}],
      // appealAttachment: [{required: true, message: '请上传申诉图片', trigger: 'change',}],
    }
    let resetForm = () => {
      editForm.appealContent = null;
      editForm.appealAttachment = null;
    }
    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos} = useForm(editForm, editFormRules);

    watch(record, (newV, preV) => {
      if (!EmptyUtil.isEmpty(newV)) {
        resetForm();
        Object.assign(editForm, newV);
        console.log('StepAppeal watch record', editForm)
        countDown();
      } else {
        resetForm();
      }
      if (newV?.appealAttachment) {
        imageUpdate.fileList = Array.isArray(newV.appealAttachment) ? newV.appealAttachment?.map((item, index) => {
          return {
            uid: index + '',
            name: item.substring(item.lastIndexOf("/") + 1),
            status: 'done',
            url: item,
          }
          // return {url: item}
        }) : newV.appealAttachment.split(',')?.map((item, index) => {
          return {
            uid: index + '',
            name: item.substring(item.lastIndexOf("/") + 1),
            status: 'done',
            url: item,
          }
        });
      } else {
        imageUpdate.fileList = [];
      }
    }, {immediate: true})


    //处罚类型 0-提醒 1-保证金 2-罚款 3-关停主叫
    let punishTypeList = PUNISH_TYPE_LIST

    //处罚量 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
    let punishValList = computed(() => {
      let res = [];
      if (record.value?.punishConfType == 0) {
        res = []
      } else if (record.value?.punishConfType == 1 || record.value?.punishConfType == 2 || record.value?.punishConfType == 4 || record.value?.punishConfType == 5) {
        res = PUNISH_AMOUNT_LIST
      } else if (record.value?.punishConfType == 3) {
        res = PUNISH_DURATION_LIST
      }
      return res;
    })

    let copyViolDesc = () => {
      if (!EmptyUtil.isEmpty(record.value?.violDesc)) {
        copy(record.value?.violDesc)
      } else {
        message.warning('暂无内容');
      }
    }
    let copyText = () => {
      if (!EmptyUtil.isEmpty(editForm.appealContent)) {
        copy(editForm.appealContent)
      } else {
        message.warning('请输入申诉说明');
      }
    }

    let showLineRecordDialog = () => {
      context.emit('showLineRecordDialog');
    }
    let cancel = () => {
      context.emit('next', -1);
    }

    let pre = () => {
      if (!isAdmin.value) {
        context.emit('next', -1);
      } else {
        context.emit('next', 9);
      }

    }
    let next = async () => {
      let confirm = true;
      let nextStatus = 15;
      if (!formDisabled.value) {
        let valid = await safePromise(editFormRef.value?.validate());
        console.log('next valid', valid, EmptyUtil.isEmpty(valid?.errorFields));
        if (EmptyUtil.isEmpty(valid?.errorFields)) {
          //在编辑阶段，需要弹窗提示
          confirm = await modalConfirm('确认提交？', '本申请仅支持提交一次，提交后无法修改，是否提交？');
        } else {
          confirm = false;
        }

        if (confirm) {
          buttonLoading.value = true;
          for (let i = 0; i < imageUpdate?.fileList?.length || 0; i++) {
            let url = imageUpdate?.fileList[i].url || imageUpdate?.fileList[i].response?.data?.url;
            let originFileObj = imageUpdate?.fileList[i].originFileObj;
            if (!url && originFileObj && (originFileObj instanceof File)) {
              const formData = new FormData()
              formData.append('file', originFileObj)
              let res = await safePromise(img.imgWebUpload(formData));
              // console.log('oConfirm res', res);
              if (res?.data?.url) {
                imageUpdate.fileList[i].url = res?.data?.url;
                imageUpdate.fileList[i].response = res;
              }
            }
          }
          editForm.appealAttachment = imageUpdate?.fileList?.filter(file => {
            let url = file?.url || file?.response?.data?.url;
            return url;
          })?.map(file => file?.url || file?.response?.data?.url)?.join(',') || '';


          let params = ObjectUtil.toParams(editForm);
          let res = await safePromise(_lineService._workOrderService.appeal(params));
          if (res?.code == 200) {
            // record.value.id = res.data?.id;
            record.value.status = nextStatus;
            record.value.appealContent = editForm.appealContent;//
            record.value.appealAttachment = editForm.appealAttachment;
            if (!isAdmin.value) {
              context.emit('next', -1);//用户申诉后，关闭弹窗
            } else {
              context.emit('next', nextStatus);//传递下一个status进行跳转
            }
            // resetForm();
          } else {
            message.error(res?.message || res?.msg || '提交失败');
          }
          buttonLoading.value = false;
        }
      } else {
        if (!isAdmin.value) {
          context.emit('next', -1);//用户申诉后，关闭弹窗
        } else {
          context.emit('next', nextStatus);//传递下一个status进行跳转
        }
      }
    }

    onUnmounted(() => {
      if (countDownId) {
        clearInterval(countDownId)
      }
    })
    return {
      labelCol: {span: 3},
      wrapperCol: {span: 14},
      isAdmin,
      isExpired, formDisabled, editForm, editFormRules, editFormRef,
      countDownDesc,
      punishTypeList, punishValList,
      imagePreviewDialogVisible, previewImage,
      imageUpdate, beforeImageUpload, onImagePreview,
      showLineRecordDialog,
      buttonLoading, pre, next, cancel, copyText, copyViolDesc,
    }
  },
  computed: {
    isOrderAppealed() {
      return this.record?.status >= 15
    }
    ,
    orderFromDesc() {
      //来源 0-平台质检 1-运营商质检
      return orderFromDesc(this.record?.orderFrom)
    }
    ,
    oprPenaltiesDesc() {
      return this.record?.oprPenalties
    }
    ,
    violDesc() {
      return this.record?.violDesc
    }
    ,
    // punishTypeDesc() {
    //   return punishTypeDesc(this.record?.punishConfType)
    // }
    // ,

    punishTypeDesc: {
      get() {
        const option = this.punishTypeList.find(
          (opt) => String(opt.value) === String(this.record.punishConfType)
        );
        return option ? option.label : '';
      },
    },
    punishValueDesc() {
      return punishValueDesc(this.record?.punishConfType, this.record?.punishConfVal)
    }
    ,
    punishPropNotice: {
      get() {
        return this.record?.punishFinalNotice;
      },
      set(value) {
        this.record.punishFinalNotice = value;
      }
    },
  },
}
</script>

<style scoped>

</style>