import {Request} from "@/http/request";

// 行业线路配置列表
export function industryList() {
    return Request.axiosInstance.post("/open/industry/list");
}

// 行业线路配置列表（行业配置界面使用，字段增多，且只能一级一级调用）
export function industryListByPid(params) {
    //{pid}
    return Request.axiosInstance.post("/open/industry/manager-list", params);
}

//根据行业id获取行业详情，包含template（话术）字段
export function queryById(parameter: any) {
    //{id}
    return Request.axiosInstance.post('/open/industry/get-by-id', parameter)
}

/**
 * 新增配置
 * @param data  name,pid
 * @returns
 */
export function industryAdd(data) {
    return Request.axiosInstance.post("/open/industry/add", data);
}

/**
 * 修改配置
 * @param data  name,id
 * @returns
 */
export function industryUpdate(data) {
    return Request.axiosInstance.post("/open/industry/update", data);
}

/**
 * 删除配置
 * @param data  id
 * @returns
 */
export function industryDelete(data) {
    return Request.axiosInstance.post("/open/industry/delete", data);
}


/**
 *  主叫报备列表
 * @param 
 * @returns
 */
export function reportList(data) {
  return Request.axiosInstance.post("/open/api-num-agent/page", data);
}
export function  agentAdd (data) {
  return Request.axiosInstance.post("/open/api-num-agent/add", data);
}

export function  deleteBatch (data) {
  return Request.axiosInstance.post("/open/api-num-agent/delete-batch", data);
}
export function  calleeWhite (data) {
  return Request.axiosInstance.post("/open/callee-white/page", data);
}

// 增加禁用小号
export function  addDisableNumber (data) {
  return Request.axiosInstance.post("/open/disable-number/add", data);
}

export function  calleeWhiteAdd (data:any) {
  return Request.axiosInstance.post("/open/callee-white/add", data);
}

export function  calleeWhiteDle (data:any) {
  return Request.axiosInstance.post("/open/callee-white/delete-batch", data);
}
export default {industryList, industryListByPid, queryById, industryAdd, industryUpdate, industryDelete,reportList,agentAdd,deleteBatch,calleeWhite,calleeWhiteAdd,calleeWhiteDle,addDisableNumber};

